import { Toast } from 'vant'
import axios from 'axios'
import store from '../store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000
})

// 不需要token的接口
const whiteListUrl = [
  '/api/biz/ac-code/receive', // 领取激活码
  '/api/promote/now-register', // 领取vip
]

service.interceptors.request.use(config=> {
  if (!whiteListUrl.includes(config.url as any)) {
    (config.headers as any)['token'] = store.state.token as any
    (config.headers as any)['Content-Type'] = 'application/json'
  }
  return config
}, error => Promise.reject(error))

service.interceptors.response.use(response => {
  const { data } = response
  switch (data.code) {
    case 200:
      return Promise.resolve(data)
    case 401:
      Toast(data.msg || '登录信息解析失败,请重新登录')
      return Promise.reject(data)
    default:
      if (data.msg !== '该手机号已注册') {
        Toast(data.msg)
      }
      return Promise.reject(data)
  }
}, error => {
  Toast(error.message)
  return Promise.reject(error)
})

export default service
