const TYPE_ARRAY = {
  0: "click", // 其它类型点击任务 点击即可领取
  1: "shareMonent", // 分享朋友圈
  2: "shareFriend", // 分享给好友
  3: "evaluate", // 5星好评
  4: "titok", // 抖音
  5: "redBook", // 小红书
  6: "kwai", // 快手
};

const REWARD_COLORS = [
  "#FBC6A9",
  "#FFDECC",
  //  "#FEF9F7"
];

const PRIZE_IMG = {
  0: "", // 现金
  1: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-19/c4cc203cfb96486a94921fee52b6dc13.png", // 数育币
  2: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-19/15818111060b4bb7bfad51345c107a02.png", // 积分
  3: "http://shuyubang.oss-cn-shenzhen.aliyuncs.com/2024-05-17/5a2f243b5d8641c7bcf944cadf4029e1.png", // vip会员
  4: "", // 抽奖机会
};
// 根据使用频率获取总次数 0-新用户仅一次,1-每日1次,2-每日2次,3-每周一次, 4-新版本更新一次
const TOTAL_COUNT = {
  0: 1,
  1: 1,
  2: 2,
  3: 1,
  4: 1,
};
export function handleTaskList(taskList: any) {
  if (taskList && taskList.length) {
    return taskList.map((item: any, index: any) => {
      const { name, actionName, useFrequency } = item || {};
      let type = (TYPE_ARRAY as any)[actionName] || "click";
      // 特殊处理
      if (name.includes('好评') || name.includes('评价') || name.includes('评论')) {
        // 特殊处理-根据关键词区分是否是好评引导任务
        type = TYPE_ARRAY[3]
      }
      if (name.includes('抖音')) {
        // 特殊处理-根据关键词区分是否是抖音分享
        type = TYPE_ARRAY[4]
      }
      if (name.includes('小红书')) {
        // 特殊处理-根据关键词区分是否是小红书分享
        type = TYPE_ARRAY[5]
      }
      if (name.includes('快手')) {
        // 特殊处理-根据关键词区分是否是快手分享
        type = TYPE_ARRAY[6]
      }
      // 添加颜色
      const color = '#519AF1'
      return {
        ...item,
        type,
        color,
        totalCount: (TOTAL_COUNT as any)[useFrequency], // 可领取次数
        autoReceive: !['shareFriend', 'shareMonent', 'evaluate', 'titok', 'redBook', 'kwai'].includes(type), // 是否自动领取
      };
    });
  } else {
    return [];
  }
}
// 获取抽奖项
export function handleLotteryRewardList(data: any) {
  if (data && data.length) {
    const rewardList = data.map((item: any, index: any) => {
      const { prizeType, prizeName } = item || {};
      // 添加颜色
      const getColor = (index: number) => {
        // 防止收尾连续两块区域颜色一致(首尾)
        if ((data.length % REWARD_COLORS.length !== 0) && index === data.length - 1) {
          return "#FEF9F7"
        }
        const index2 = index % REWARD_COLORS.length;
        return REWARD_COLORS[index2];
      };
      // 添加图片
      const prizeImg = item.prize_img || (PRIZE_IMG as any)[prizeType];
      // 特殊处理-识别谢谢惠顾
      const isThankyou = prizeType == 99 && prizeName.includes('谢谢');
      return {
        ...item,
        color: getColor(index),
        prizeImg,
        isThankyou,
      };
    });
    return rewardList;
  } else {
    return [];
  }
}
