import Vue from "vue";
import Vuex from "vuex";
import {
  isAndroid,
  isIOS
} from "@/utils/tsUtils";
import { xApp } from "@/utils/xapp";
Vue.use(Vuex);
import { parentactionrewards, getShareImages, getUserInfo, checkResultPopou } from "@/api/lottery";

// 处理数据
export default new Vuex.Store({
  state: {
    userInfo: null, // 用户信息
    taskList: [], // 任务列表
    selectedShareImageId: null, // 当前选中的分享图片Id
    shareImages: [], // 分享图片
    token: window.sessionStorage.getItem("token"),
    // token:
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJwY1Rva2VuOjI5OCIsImV4cCI6MTY5MDcxMDg5M30.RBUc4l_tXM3XZEKYkbx6gUhFmcc4FhSpntjPIHZ4T_0",
    appVersion: '', // app版本号
    shearPlateText: '', // app版本号
    city: '', // app版本号
    resultPopou: null // 内容分享
  },
  getters: {
    // 分享首页展示
    shareImageList(state) {
      const pageSize = 4;
      const list = [];
      // 给一个一位数组, 将其变为二维数组，每个子数组有pageSize个元素, 装完为止
      for (let i = 0; i < state.shareImages.length; i += pageSize) {
        list.push(state.shareImages.slice(i, i + pageSize));
      }
      return list;
    },
    // 当前选中的分享图片
    selectedShareImage(state) {
      return state.shareImages.find(
        (item: any) => {
          return item.id === state.selectedShareImageId
        }
      );
    },
    // 当前选中的分享图片在列表中的索引
    selectedShareImageIndex(state) {
      const { selectedShareImageId, shareImages } = state;
      return shareImages.findIndex(
        (item: any) => {
          return item.id === selectedShareImageId
        }
      );
    },
  },
  mutations: {
    // 设置用户信息
    setUserInfo(state, paload) {
      state.userInfo = paload;
    },
    // 设置当前选中的分享图片Id
    setSelectedShareImageId(state, paload) {
      state.selectedShareImageId = paload;
    },
    setToken(state, token) {
      state.token = token;
      window.sessionStorage.setItem("token", token);
    },
    setAppVersion(state, appVersion) {
      state.appVersion = appVersion || '3.7.7';
      window.sessionStorage.setItem("appVersion", appVersion || '3.7.7');
    },
    setShearPlateText(state, shearPlateText) {
      state.shearPlateText = shearPlateText
    },
    setCity(state, city) {
      state.city = city;
    },
    // 设置分享图片
    setShareImages(state, shareImages) {
      state.shareImages = shareImages;
    },
    // 设置任务列表
    setTaskList(state, taskList) {
      state.taskList = taskList;
    },
    // 验证或无视发布结果
    setResultPopou(state, resultPopou) {
      state.resultPopou = resultPopou;
    },
  },
  actions: {
    // 获取当前定位城市
    async setCity({ commit }) {
      if (isAndroid() && window.android) {
        const city = await window.android.getCity();
        commit('setCity', city)
        return city;
      } else if (isIOS()) {
        xApp.setupWebViewJavascriptBridge((bridge: any) => {
          bridge.callHandler("getCity", {}, (city: string) => {
            commit('setCity', city)
          });
        });
      }
    },
    // 获取剪切板信息
    async setShearPlateText({ commit }) {
      if (isAndroid() && window.android) {
        const text = await window.android.getShearPlateText();
        commit('setShearPlateText', text)
        return text;
      } else if (isIOS()) {
        xApp.setupWebViewJavascriptBridge((bridge: any) => {
          bridge.callHandler("setShearPlateText", {}, (shearPlateText: string) => {
            commit('setShearPlateText', shearPlateText)
          });
        });
      }
    },
    // 获取app版本
    setAppVersion({ commit }) {
      if (isAndroid() && window.android && window.android.getAppVersion) {
        commit('setAppVersion', window.android.getAppVersion())
      } else if (isIOS()) {
        xApp.setupWebViewJavascriptBridge((bridge: any) => {
          bridge.callHandler("getAppVersion", {}, (iosVersion: string) => {
            commit('setAppVersion', iosVersion)
          });
        });
      }
    },
    // 获取用户信息
    async getUserInfo({ commit }, { refreshNow = true } = {}) {
      if (!this.state.userInfo || refreshNow) {
        const userInfo = await getUserInfo();
        commit('setUserInfo', userInfo)
      }
      return this.state.userInfo
    },
    // 获取分享图片
    async getShareImages({ commit }) {
      if (!this.state.shareImages?.length) {
        const shareImages = await getShareImages();
        commit('setShareImages', shareImages)
      }
      return this.state.shareImages;
    },
    // 获取任务列表
    async getTaskList({ commit }, { refreshNow = true } = {}) {
      if (!this.state.taskList?.length || refreshNow) {
        const taskList = await parentactionrewards();
        commit('setTaskList', taskList)
      }
      return this.state.taskList;
    },
    // 抖音分享-验证或无视发布结果
    async checkResultPopou({ commit }) {
      const data = await checkResultPopou();
      commit('setResultPopou', data)
      return data
    },
  },
  modules: {},
});
