import router from './router';
import store from './store';

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    store.commit('setToken', to.query.token);
  }
  // 获取app版本号
  store.dispatch('setAppVersion');
  next();
});
