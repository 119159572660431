import { Toast } from "vant";
import request from "../utils/request";
import { lotteryMap } from "./map";
import store from '@/store';
// 用户信息
export async function getUserInfo() {
  try {
    const res = await request({
      url: `/api/biz/person/account/info`,
      method: "get",
    });
    const { code, data } = res as any;
    if (code === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    Toast("error");
    return null;
  }
}

/**
 * 获取分享图片选择列表
 * @return {*}
 */
export async function getShareImages() {
  const res = await request({
    url: `/api/biz/v1/parentsharematerials/list`,
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}
// 兑换明细
export async function parentrewardflows(params: any) {
  const { page = 1, limit = 10 } = params as any;
  const res = await request({
    url: `/api/biz/v1/parentrewardflows/page`,
    method: "get",
    params: {
      page,
      limit,
    },
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 分享行为列表
export async function parentactionrewards() {
  const res = await request({
    url: "/api/biz/v1/parentactionrewards/list",
    method: "get",
    headers: {
      version: store.state.appVersion || '3.7.7.2'
    }
  });
  const { code, data } = res as any;
  if (code === 200) {
    return lotteryMap.handleTaskList(data);
  }
}

// 分享结果奖励查询
export async function getShareReward() {
  const res = await request({
    url: "/api/biz/v1/parentshareresults/list",
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 分享结果奖励查询-区分类型 type: 0-现金,1-数育币,2-积分,3-vip会员
export async function getShareRewardByType(params: any) {
  const { page = 1, limit = 10 } = params as any;
  const res = await request({
    url: "/api/biz/v1/parentrewardflows/rewardType/page",
    method: "get",
    params: {
      page,
      limit,
      ...params
    },
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 点击领取或分享
export async function shareOrGet(id: string, sharePlatform: string | undefined) {
  try {
    const headers: any = {
      version: store.state.appVersion || '3.7.7.2'
    }
    if (sharePlatform) {
      headers.platform = sharePlatform
    }
    console.log('headers: ', headers);
    const res = await request({
      url: `/api/biz/v1/parentactionrewards/shareOrGet/${id}`,
      method: "post",
      headers
    });
    const { code, msg } = res as any;
    const obj = {
      success: false,
      msg: "",
    };
    if (code === 200) {
      obj.success = true;
    } else {
      obj.success = false;
      obj.msg = msg;
    }
    return obj;
  } catch (error) {
    return {
      success: false,
      msg: '失败'
    }
  }
}

// 获取抽奖消耗积分
export async function getConsumeScore() {
  const res = await request({
    url: "/api/biz/v1/parentlotterysets/points",
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return Number(data);
  }
}
// 获取抽奖项
export async function getLotteryRewardList() {
  // prizeType 0-现金' 1-数育币' 2-积分' 3-VIP天数' 4-抽奖机会' 99-其它'
  const res = await request({
    url: "/api/biz/v1/parentlotterysets/list",
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return lotteryMap.handleLotteryRewardList(data);
  }
}
// 进行抽奖
export async function doLottry() {
  const res = await request({
    url: "/api/biz/v1/parentlotterysets",
    method: "post",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}
// 中奖记录查询  0-现金,1-数育币,2-积分,3-vip会员
export async function getWinningRecord(params: any) {
  const { page = 1, limit = 10 } = params as any;
  const res = await request({
    url: "/api/biz/v1/parentrewardflows/winningRecord",
    method: "get",
    params: {
      page,
      limit,
    },
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 获取内容分享素材
export async function getContentShareImages() {
  const res = await request({
    url: "/api/biz/v1/parenttiktokmaterials/list",
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 内容分享-判断是否弹出验证发布成果
export async function checkResultPopou() {
  const res = await request({
    url: "/api/biz/v1/parentsharerecords",
    method: "get",
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data;
  }
}

// 内容分享-验证或无视发布结果
export async function checkLinkOrClosePop(params: any) {
  const res = await request({
    url: "/api/biz/v1/parentsharerecords/checkLinkOrClosePop",
    method: "post",
    data: params,
  });
  const { code, data } = res as any;
  if (code === 200) {
    return data || true;
  }
}