import Vue from "vue";
import VConsole from 'vconsole'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import "./permission";
import "./vant";
// require('../mock/index') // 开发时开启

if (process.env.NODE_ENV === 'development') {
  window.NODE_ENV = process.env.NODE_ENV
  new VConsole()
}
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to?.meta?.title) document.title = to.meta.title;
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
