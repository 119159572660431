/* eslint-disable */
import { Tools } from 'common-pkg';

const appName = "jodiy.cn/app-v1.0.0"

export const xApp: any = {
  getAppVersion () { // 比如：APP输出到webview的userAgent里面的串带定义好的 yourAppName/1.1-debug 则调用方法xApp.getAppVersion('yourAppName') => '1.1-debug'
    const reg = new RegExp('(^|\\s)' + appName + '\/([\\w-_\.]+)(\/|$)', 'i')
    // 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1 jodiy.cn/app-v1.0.0'
    const r = navigator.userAgent.match(reg)
    // const r = 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1 jodiy.cn/app-v1.0.0'.match(reg)
    if (r != null) {
      return r[2]
    }
    return null
  },
  setupWebViewJavascriptBridge (callback: Function) { // webview端发起建立与App端的桥接
    console.log('setupWebViewJavascriptBridge init ...')
    const _self = window as any
    if (!_self.document) return
    console.log('WebViewJavascriptBridge init ...')
    if (_self.WebViewJavascriptBridge) { return callback(_self.WebViewJavascriptBridge) }
    if (_self.WVJBCallbacks) { return _self.WVJBCallbacks.push(callback) }
    _self.WVJBCallbacks = [callback]
    const WVJBIframe = _self.document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    if (Tools.Web.getIsAndroid()) { // 安卓与IOS端可能会出现一边用大写一边用小写，最好沟通好都用大写或者都用小写就不用分开处理。[这里要按需修改]
      WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    } else {
      WVJBIframe.src = 'https://__bridge_loaded__'
    }
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(function () { _self.document.documentElement.removeChild(WVJBIframe) }, 0)
  },
  sendToApp: null, // Webview与App端交互的核心方法
  dataFromApp: null, // App共享给webview端的可用数据
  shareData: {}, // 分享到微信/QQ/..的数据设置
  shareCallback (data: any){}, // 分享回调函数，可按需在页面中重新定义
  ready (callback: Function) { // 监听App端初始化webview是否完成，若考虑到所有的webview都是支持cookie，App端可以直接把dataFromApp数据写到cookie方便调用，就不需要执行此函数
    if (xApp.isInApp()) {
      const __getIdTimer = setInterval(function () {
        if (xApp.dataFromApp) {
          callback(xApp.dataFromApp)
          clearInterval(__getIdTimer)
        }
      }, 10)
    }
  }
}
