<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    window.sessionStorage.clear();
  },
};
</script>

<style lang="less">
html,
body {
  height: 100%;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "PingFang SC-Medium", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #323232;
  overflow: hidden;
}

.van-toast {
  width: auto !important;
  top: 80% !important;
}

// 自定义字体
@font-face {
  font-family: "PingFang SC-Medium";
  src: url("./assets/fonts/PingFang Medium.ttf") format("ttf");
}
</style>
